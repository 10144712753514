import React, { Component } from 'react';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

class Page_Register extends Component {

    constructor() {
        super();
        this.state = {
            tb_Login_Value: null,
            tb_PW_Value: '',
        }
    }

    async click_CheckAuthentication() {
        console.log('login = ' + this.state.tb_Login_Value);
        console.log('password = ' + this.state.tb_PW_Value);

    }


    render() {

        return (
            <div >
                <h1>Page Register</h1>
                {/* <InputText value={this.state.tb_Login_Value} onChange={(e) => this.setState({ tb_Login_Value: e.target.value })} />

                <Password value={this.state.tb_PW_Value} onChange={(e) => this.setState({ tb_PW_Value: e.target.value })} />

                <Button label="Login" onClick={() => { this.click_CheckAuthentication() }} /> */}
            </div >
        );
    }
}

export default Page_Register;
