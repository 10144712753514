import React, { Component, useState } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

function Navbar_Custome() {


    return (
        <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
            <Navbar.Brand href="#">eclipsesystem.com.my</Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="#features">Features</Nav.Link>
                    <Nav.Link href="#pricing">Pricing</Nav.Link>
                    <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="#a 1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#a 2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#a 3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    <Nav.Link href="#e1">empty 1</Nav.Link>
                    <Nav.Link eventKey={2} href="#e2">
                        empty 2
                 </Nav.Link>
                </Nav>
            </Navbar.Collapse> */}
        </Navbar>
    );
}


export default Navbar_Custome;