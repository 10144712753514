import React, { Component } from 'react';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// import { Button } from 'primereact/button';
import Button from 'react-bootstrap/Button'

class Page_About extends Component {

    constructor() {
        super();
        this.state = {

        }
    }

    render() {

        return (
            <div >
                <h1>Page About</h1>

            </div >
        );
    }
}

export default Page_About;
