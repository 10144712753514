import React, { Component, useState, createRef } from "react";

// import 'primereact/resources/themes/nova-light/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
// import { Button } from 'primereact/button';
import "../primeflex.css";

import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Navbar from "../Components/Navbar";

import ControlledCarousel from "../Components/ControlledCarousel";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import Overlay from "react-bootstrap/Overlay";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class Page_Home extends Component {
  constructor() {
    super();
    this.state = {
      tb_GoogleSearch: "",
    };
  }

  textInput_focus = createRef();

  googleSearch(searchParameter) {
    window.location.href = "https://www.google.com/search?q=" + searchParameter;
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.googleSearch(this.state.tb_GoogleSearch);
    }
  };

  renderTooltip(props, text) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        <div style={{ fontSize: "20px", color: "#FFFEBF" }}>{text}</div>
      </Tooltip>
    );
  }

  componentDidMount() {
    this.textInput_focus.current.focus(); // focus the search bar
  }

  render() {
    const demos = {
      GoogleMap:
        '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.2361898062245!2d101.70601811475709!3d3.031179897792537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x554dd90d36367be6!2sEclipse%20System%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1599576514327!5m2!1sen!2smy" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
      // '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.2363284177804!2d101.70610931527638!3d3.0311422547107667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcaaaf4c62b1d%3A0x6826ade645319116!2s39%2C%20Jalan%20SP%202%2F2%2C%20Taman%20Serdang%20Perdana%2C%2043300%20Seri%20Kembangan%2C%20Selangor!5e0!3m2!1sen!2smy!4v1599576267755!5m2!1sen!2smy" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
      // '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3984.2403231304206!2d101.708492!3d3.0300572!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcaaaf4c62b1d%3A0x6826ade645319116!2s39%2C%20Jalan%20SP%202%2F2%2C%20Taman%20Serdang%20Perdana%2C%2043300%20Seri%20Kembangan%2C%20Selangor!5e0!3m2!1sen!2smy!4v1594304572124!5m2!1sen!2smy" width="400" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
    };

    return (
      <div style={{ backgroundColor: "#000000" }}>
        <Navbar />

        <div style={{ textAlign: "center" }}>
          <ControlledCarousel />
        </div>

        <br />
        <div align="center">
          <tr>
            <td></td>

            <td>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1"></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  ref={this.textInput_focus}
                  id="asd"
                  placeholder="SearchKeyword"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={this.state.tb_GoogleSearch}
                  onChange={(e) =>
                    this.setState({ tb_GoogleSearch: e.target.value })
                  }
                  onKeyDown={this._handleKeyDown}
                />
                <Button
                  variant="primary"
                  onClick={() => this.googleSearch(this.state.tb_GoogleSearch)}
                >
                  Google Search
                </Button>
              </InputGroup>
            </td>
            <td></td>
          </tr>
        </div>

        <br />
        <br />
        <h2 style={{ textAlign: "center", color: "white" }}>Popular</h2>

        <div style={{ textAlign: "center" }}>
          <Button variant="link">
            <OverlayTrigger
              placement="right"
              // flip='true'
              delay={{ show: 250, hide: 0 }}
              overlay={this.renderTooltip("", "Facebook")}
            >
              <img
                src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_facebook.png"
                width="70px"
                onClick={() => window.open("https://www.facebook.com/")}
              />
            </OverlayTrigger>
          </Button>{" "}
          <Button variant="link">
            <OverlayTrigger
              placement="right"
              // flip='true'
              delay={{ show: 250, hide: 0 }}
              overlay={this.renderTooltip("", "Google")}
            >
              <img
                src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_Googlesearch.png"
                width="70px"
                onClick={() => window.open("https://www.google.com/")}
              />
            </OverlayTrigger>
          </Button>{" "}
          <Button variant="link">
            <OverlayTrigger
              placement="right"
              // flip='true'
              delay={{ show: 250, hide: 0 }}
              overlay={this.renderTooltip("", "Youtube")}
            >
              <img
                src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_youtube.png"
                width="70px"
                onClick={() => window.open("https://www.youtube.com")}
              />
            </OverlayTrigger>
          </Button>{" "}
        </div>

        <br />
        <br />
        <hr />

        <br />
        <br />
        <hr />

        <div className="p-grid">
          <div className="p-col-4 p-md-4 p-lg-4">
            <p align="center">
              <h5 style={{ textAlign: "center", color: "white" }}>SEARCH</h5>
              <Button
                variant="link"
                onClick={() => window.open("https://www.google.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Google")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_Googlesearch.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.yahoo.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Yahoo")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_yahoo.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.bing.com")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Bing")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_bing.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.baidu.com")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Baidu")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_baidu.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>

          <div className="p-col-4 p-md-4 p-lg-4">
            <h5 style={{ textAlign: "center", color: "white" }}>LAISURE</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://www.youtube.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Youtube")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_youtube.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.netflix.com/my/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Netflix")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_netflix.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.viu.com/ott/my/en/all")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "VIU")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_viu.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>

          <div className="p-col-4 p-md-4 p-lg-4">
            <h5 style={{ textAlign: "center", color: "white" }}>EMAIL</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://www.gmail.com/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Gmail")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_gmail.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://mail.yahoo.com/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Yahoo Mail")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_yahoo-mail.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://outlook.live.com/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "OutLook")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_outlook.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>
        </div>

        <hr />
        <div className="p-grid">
          <div className="p-col-4 p-md-4 p-lg-4">
            <p align="center">
              <h5 style={{ textAlign: "center", color: "white" }}>
                SOCIAL MEDIA
              </h5>
              <Button
                variant="link"
                onClick={() => window.open("https://www.facebook.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Facebook")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_facebook.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://twitter.com")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Twitter")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_twitter.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.linkedin.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "LinkedIn")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_linkedin.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.Instagram.com")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Instagram")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_instagram.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>

          <div className="p-col-4 p-md-4 p-lg-4">
            <h5 style={{ textAlign: "center", color: "white" }}>CHAT TOOLS</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://web.whatsapp.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Whatsapp")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_whatsapp.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://web.wechat.com")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "WeChat")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_wechat.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.skype.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Skype")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_skype.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.discord.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Discord")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_discord.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.snapchat.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Snapchat")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_snapchat.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>

          <div className="p-col-4 p-md-4 p-lg-4">
            <h5 style={{ textAlign: "center", color: "white" }}>ETC</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://sades.my")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Sades")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_sades.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.alitrade.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Maxsun")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_maxsun.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://madtech.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "MadTech")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_madtech.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.alitrade.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Titan Army")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_titanarmy.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>
        </div>

        <hr />
        <div className="p-grid">
          <div className="p-col-4 p-md-4 p-lg-4">
            <h5 style={{ textAlign: "center", color: "white" }}>DISCUSSION</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://www.reddit.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Reddit")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_reddit.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>

          <div className="p-col-4 p-md-4 p-lg-4">
            <h5 style={{ textAlign: "center", color: "white" }}>OFFICE</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://www.office.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Microsoft Word")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_Microsoft%20word.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.office.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Microsoft Excel")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_Microsoft%20excel.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.office.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Microsoft Powerpoint")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_powerpoint.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.pdfcandy.com/")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "PDF Candy")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_pdfcandy.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>

          <div className="p-col-4 p-md-4 p-lg-4">
            <h5 style={{ textAlign: "center", color: "white" }}>NEWS</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://www.nst.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "New Straits Times")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_nst.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.thestar.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "The Star")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_thestar.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.chinapress.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "China Press")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_chinapress.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.sinchew.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Sinchew")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/ico_cafe_eclipsemy//ico_sinchew.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>
        </div>

        <hr />
        <div className="p-grid">
          <div className="p-col-12 p-md-12 p-lg-12">
            <h5 style={{ textAlign: "center", color: "white" }}>MAP</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://www.google.com.my/maps")}
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Google Map")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_googlemap.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.waze.com/en-GB/livemap")
                }
              >
                <OverlayTrigger
                  placement="right"
                  // flip='true'
                  delay={{ show: 250, hide: 0 }}
                  overlay={this.renderTooltip("", "Waze Map")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_wazemap.png"
                    width="40px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>
        </div>

        <hr />
        <div className="p-grid">
          <div className="p-col-12 p-md-12 p-lg-12">
            <h5 style={{ textAlign: "center", color: "white" }}>BANK</h5>
            <p align="center">
              <Button
                variant="link"
                onClick={() => window.open("https://www.affinbank.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Affin Bank Berhad")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_01.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.alliancebank.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Alliance Bank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_02.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.ambank.com.my/eng/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Am Bank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_03.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.bnpparibas.com.my/en/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "BNP Paribas Malaysia")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_04.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.bangkokbank.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Bangkok BANK")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_05.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open(
                    "https://www.bofaml.com/en-us/content/apac-malaysia.html/"
                  )
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Bank of America Malaysia")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_06.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.bankofchina.com.my/en/")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Bank of China (Malaysia)")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_07.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.cimbclicks.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "CIMB Bank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_08.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("http://my.ccb.com/malaysia/en/index.html")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip(
                    "",
                    "China Construction Bank (Malaysia)"
                  )}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_09.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.citibank.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Citibank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_10.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.db.com/malaysia/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Deutsche Bank (Malaysia)")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_11.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://sp.hsbc.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "HSBC Bank Malaysia")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_12.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.hlb.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Hong Leong Bank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_13.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.indiainternationalbank.com.my/")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip(
                    "",
                    "India International Bank (Malaysia)"
                  )}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_14.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.icbc.com.cn/ICBC/en/default.htm")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip(
                    "",
                    "Industrial and Commercial Bank of China"
                  )}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_15.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.jpmorganchase.com/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "J.P. Morgan Chase Bank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_16.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.bk.mufg.jp/malaysia/index.html")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "MUFG Bank (Malaysia)")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_17.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.maybank.com/en/index.page")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Malayan Banking")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_18.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.mizuhobank.com/malaysia/index.html")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Mizuho Bank (Malaysia)")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_19.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.ocbc.com.my/group/group-home.html")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "OCBC Bank (Malaysia) ")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_20.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.pbebank.com/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Public Bank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_21.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.rhbgroup.com/index.html")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "RHB Bank ")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_22.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.sc.com/my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "Standard Chartered Bank")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_23.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://www.smbc.co.jp/global/malaysia/")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip(
                    "",
                    "Sumitomo Mitsui Banking Corporation Malaysia "
                  )}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_24.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() =>
                  window.open("https://scotiabank.com.my/scotiabank/index.php")
                }
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip("", "The Bank of Nova Scotia")}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_25.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => window.open("https://www.uob.com.my/")}
              >
                <OverlayTrigger
                  placement="left"
                  // flip='true'
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip(
                    "",
                    "United Overseas Bank (Malaysia)"
                  )}
                >
                  <img
                    src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/ico/ico_bank_26.png"
                    width="100px"
                  />
                </OverlayTrigger>
              </Button>{" "}
            </p>
          </div>
        </div>

        <div style={{ backgroundColor: "#1a0000", color: "white" }}>
          <br />
          <br />
          <center>&#9930; Cyber Cafe Solution & Maintenance</center>

          <center>&#9930;Diskless System</center>

          <center>&#9930; Game Auto Update</center>

          <center>&#9742; Mr.Tan 012 - 617 9359</center>
          <center>
            <p>
              {" "}
              <a href="https://api.whatsapp.com/send?phone=60126179359&text=I%27m%20interested%20in%20your%20Eclipse%20System%20Solution&source=&data=">
                whatsapp to us now!
              </a>
            </p>
          </center>
          <p align="center">
            <Iframe iframe={demos["GoogleMap"]} allow="autoplay" align />
          </p>
        </div>
      </div>
    );
  }
}

export default Page_Home;

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}
