import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { TabMenu } from 'primereact/tabmenu';



class Nav extends Component {
    constructor() {
        super();
        this.state = {
            items: [
                { label: 'Home', icon: 'pi pi-fw pi-home', style: { 'fontSize': '1.5em' } },
                { label: 'Cafe Hardware - Lite', icon: 'pi pi-fw pi-pencil' },
                { label: 'Cafe Hardware - Full', icon: 'pi pi-chart-line' },
                { label: 'Cafe Hardware - Backup', icon: 'pi pi-fw pi-file' },
            ],
            activeItem: null
        }
    }


    goTo_Page = (x) => {



        if (x != null) {
            if (x == 'Home') {
                window.location.assign("#/Page_Cafe_Copy/");
            }
            if (x == 'Cafe') { window.location.assign("#/Page_Cafe/"); }
            if (x == 'About') { window.location.assign("#/Page_About"); }
            if (x == 'Login') { window.location.assign("#/Page_Login"); }

        }
    }

    render() {



        return (

            <div >
                {/* {window.location.hash} */}
                {/* <ul className='nav-links'>
                    <Link to='/'>
                        <li >Home</li>
                    </Link>
                    <Link to='/Page_Cafe'>
                        <li >Cafe Spec (On Live - Full)</li>
                    </Link>
                    <Link to='/Page_Cafe_Lite'>
                        <li >Cafe Spec (On Live - Lite)</li>
                    </Link>
                    <Link to='/Page_Cafe_Copy'>
                        <li >Cafe Spec (Offiline - Backup )</li>
                    </Link>
                    <Link to='/Page_About'>
                        <li >About</li>
                    </Link>
                </ul> */}

                {/* <TabMenu model={this.state.items} activeItem={this.state.activeItem} onTabChange={(e) => this.setState({ activeItem: e.value.label })} />
                {this.goTo_Page(this.state.activeItem)} */}

            </div >
        );
    }
}

export default Nav;
