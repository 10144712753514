import React, { Component, useState } from "react";

import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <Button
          variant="link"
          onClick={() => window.open("https://www.facebook.com/MaxsunMY")}
        >
          <img
            className="d-block w-100"
            src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/Carousel/001.jpg"
            alt="First slide"
          />

          <Carousel.Caption>
            {/* <h3>Maxsun Malaysia</h3>
                        <p>....</p> */}
          </Carousel.Caption>
        </Button>
      </Carousel.Item>
      <Carousel.Item>
        <Button
          variant="link"
          onClick={() => window.open("https://www.facebook.com/madtech.my")}
        >
          <img
            className="d-block w-100"
            src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/Carousel/002.jpg"
            alt="Second slide"
          />
        </Button>
        <Carousel.Caption>
          {/* <h3>Madtech Malaysia</h3>
                    <p>....</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Button variant="link" onClick={() => window.open("https://sades.my/")}>
          <img
            className="d-block w-100"
            src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/Carousel/003.jpg"
            alt="Third slide"
          />
        </Button>
        <Carousel.Caption>
          {/* <h3>Sades Malaysia</h3>
                    <p>....</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Button
          variant="link"
          onClick={() => window.open("https://www.facebook.com/TitanArmyMy/")}
        >
          <img
            className="d-block w-100"
            src="https://eclipseapi3.freemyip.com/uploads/eclipseSystem_web/Carousel/004.jpg"
            alt="Third slide"
          />
        </Button>
        <Carousel.Caption>
          {/* <h3>Titan Army Malaysia</h3>
                    <p>....</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
