import React from 'react';
import './App.css';

import Nav from './Nav';
import Page_Home from './Page/Page_Home';
import Page_Cafe from './Page/Page_Cafe';
import Page_Cafe_Copy from './Page/Page_Cafe_Copy';
import Page_Cafe_Lite from './Page/Page_Cafe_Lite';


import Page_About from './Page/Page_About';
import Page_Login from './Page/Page_Login';
import Page_Register from './Page/Page_Register';

import { Button } from 'react-bootstrap';

import { HashRouter, Route, Switch } from 'react-router-dom'; // router 1/  


function App() {


  return (

    <HashRouter >

      <div className="App"  >
        <Nav />
        <Switch>
          <Route path='/' exact component={Page_Home} />

          <Route path='/Page_Cafe/' component={Page_Cafe} />
          <Route path='/Page_Cafe_Lite/' component={Page_Cafe_Lite} />
          <Route path='/Page_Cafe_Copy/' component={Page_Cafe_Copy} />

          <Route path='/Page_About' component={Page_About} />
          <Route path='/Page_Login' component={Page_Login} />
          <Route path='/Page_Register' component={Page_Register} />

        </Switch>
      </div >
    </HashRouter >

  );
}


// const Page_Home = () => (
//   <div>
//     <h1>Page_Home</h1>
//   </div>
// )


export default App;
