import React, { useState, useEffect, Component } from "react";
import "../App.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { ScrollPanel } from "primereact/scrollpanel";

export class Page_Cafe extends Component {
  constructor() {
    super();
    this.state = {
      data_FromDB: [],
    };
  }

  get_DataFromDB = async () => {
    const tempData = await fetch(
      "https://eclipseapi3.freemyip.com/get_careu_hardwareinfo"
    );
    const jsonData = await tempData.json();
    this.setState({ data_FromDB: jsonData });
  };

  export = async () => {
    this.dt.exportCSV();
  };

  componentDidMount() {
    this.get_DataFromDB();
  }

  render() {
    const header = (
      <div>
        <div style={{ textAlign: "left" }}>
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="50"
          />{" "}
          <Button
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            label="Export"
            onClick={this.export}
          ></Button>
        </div>
      </div>
    );

    return (
      <div>
        <h1>Page Shop - Full</h1>

        <ScrollPanel style={{ width: "200%" }} className="custombar2">
          <div style={{ padding: "1em", lineHeight: "1.5" }}>
            <DataTable
              value={this.state.data_FromDB}
              paginator={true}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              rows={100}
              rowsPerPageOptions={[100, 500, 1000]}
              sortMode="multiple"
              header={header}
              globalFilter={this.state.globalFilter}
              emptyMessage="No records found"
              selectionMode="multiple"
              // responsive={true}

              resizableColumns={true}
              columnResizeMode="expand"
              ref={(el) => {
                this.dt = el;
              }}
            >
              <Column
                field="id"
                header="id"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_supersmallFont"
                style={{ width: "2%" }}
              />
              <Column
                field="id_generate_datetime"
                header="Generate at"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                filterMatchMode="contains"
                className="dt_supersmallFont"
                style={{ width: "2.5%" }}
              />
              <Column
                field="cafe"
                className="dt_normalFont"
                header="Cafe"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                style={{ width: "4%" }}
              />
              <Column
                field="pc_name"
                header="PC"
                header="Cafe"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_normalFont"
                style={{ width: "2%" }}
                sortable={true}
              />
              <Column
                field="pc_info"
                header="PC_Info"
                header="PC_Info"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "10%" }}
              />
              <Column
                field="PC_StartUpTime"
                header="PC_Startup"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="CPU_Name"
                header="CPU"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "6%" }}
              />
              <Column
                field="CPU_ProcessorID"
                header="ProcessorID"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_supersmallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="CPU_VirtualizationFirmwareEnabled"
                header="CPU_Virtualization"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2%" }}
              />
              <Column
                field="GPU_Name"
                header="GPU"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "6%" }}
              />
              <Column
                field="GPU_AdapterRAM"
                header="GPU RAM"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2%" }}
              />
              <Column
                field="MOBO_Manufacturer"
                header="MOBO"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "6%" }}
              />
              <Column
                field="MOBO_Product"
                header="MOBO 2"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="MOBO_SerialNumber"
                header=" MOBO SN"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_supersmallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="MOBO_Version"
                header="MOBO VER"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "3%" }}
              />
              <Column
                field="RAM_Manufacturer"
                header="RAM"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="RAM_PartNumber"
                header="RAM PN"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_supersmallFont"
                style={{ width: "6%" }}
              />
              <Column
                field="RAM_Capacity"
                header="RAM Capacity"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="NIC_InstanceName"
                header="NIC"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="NIC_Speed"
                header="NIC Speed"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2%" }}
              />
              <Column
                field="MON_Manufacturer"
                header="MON"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "6%" }}
              />
              <Column
                field="MON_FPS"
                header="MON FPS"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2%" }}
              />
              <Column
                field="LoginFrom"
                header="From"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2.5%" }}
              />
              <Column
                field="LoginUntil"
                header="Until"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2.5%" }}
              />
              <Column
                field="LoginDuration"
                header="Duration"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2%" }}
              />
              <Column
                field="SuccessfulClose"
                header="SuccessfulClose?"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "2%" }}
              />
              <Column
                field="Alert"
                header="Alert"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="NoteDesc"
                header="Note"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
              <Column
                field="ver"
                header="Ver"
                sortable={true}
                filter={true}
                filterMatchMode="contains"
                className="dt_smallFont"
                style={{ width: "4%" }}
              />
            </DataTable>
          </div>
        </ScrollPanel>
      </div>
    );
  }
}

export default Page_Cafe;
